import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On Saturday January 16 Geosoft celebrated its 30th anniversary with a Gala
Evening at the Royal  Conservatory in Toronto. Colin Reeves was invited to take
part as one of the founders of the  company. About 150 employees and spouses
attended the evening with many receiving awards for  long service to the
company. `}</p>
    <p>{`The idea started in 1981 when both Ian MacLeod (now Chief Technologist at
Geosoft) and Colin  became aware that small, so-called 'micro-computers', were
becoming available with graphic  capabilities that could accelerate the process
of geophysical interpretation. For example,  calculated anomalies for a
hypothetical body could be compared with observed anomalies directly  on the
screen, by-passing a visit to a remote data centre with a deck of punch-cards
and the  subsequent need to plot the results by hand - a turn-round time of at
least 24 hours. Colin and Ian  eventually pruchased their own IBM-PC within six
months of its first appearance as this appeared  to be a system that would
likely break into the mass market. Geophysical software to capitalise on  this
was written in 1982 as the pair gained familiarity with the system's
capabilities. An old  monochrome TV served as the first monitor. Colour came
later! `}</p>
    <p>{`Colin bade a fond farewell to Toronto in the summer of 1983 to take up his new
post as geophysics  professor at ITC in Delft. Ian persevered with the Geosoft
concept and eventually decided on an  independent existence for the company,
outside of the geophysical consulting environment.  Geosoft was founded as an
entity in 1986 and has grown steadily from its small beginnings to give
employment to way over 100 employees with regional offices in Europe, South
America, Africa and  Australia in addition to the head office on the Toronto
waterfront. 'Microcomputers' have become  more numerous than motor cars across
the whole world and Geosoft has become a by-word for  geophysical software
wherever geophysical mapping is carried out. `}</p>
    <p>{`Ian and Colin at the Gala appear in the picture (courtesy of Geosoft) below.`}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      